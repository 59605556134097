/* eslint-disable @shopify/typescript/prefer-pascal-case-enums */
import type {MetricsConfig} from '@shopify/opentelemetry-sdk';

enum MetricInstrumentType {
  Histogram = 'Histogram',
  Counter = 'Counter',
  UpDownCounter = 'UpDownCounter',
}

enum ValueType {
  INT = 0,
  DOUBLE = 1,
}

export enum TelemetryMetricId {
  RequestShowCalledBeforeIframeLoaded = 'shop_js_request_show_called_before_iframe_loaded',
}

export const metrics: MetricsConfig = {
  [TelemetryMetricId.RequestShowCalledBeforeIframeLoaded]: {
    type: MetricInstrumentType.Counter,
    description:
      'Number of times requestShow is called before iframe is loaded',
    valueType: ValueType.INT,
  },
};
